import React, { useState } from 'react';
import XMR from "../images/xmr.svg"

const XMRModal = ( props ) => {

        const ADDRESS = "4AC99QFmCipcMnEwLNuRcB83DqZ3Y7AApEcvsocegkUT6XF8W3JyxiQVxw7acoiSBhE8NYUi5ppMihFoL7RnDaW8AHdeXx4";

        const [copied, setCopied] = useState(false);

        const copyTextToClipboard = async (text) => {
            try{
                if ('clipboard' in navigator) {
                    await navigator.clipboard.writeText(text);
                    setCopied(true);
                } else {
                    return document.execCommand('copy', true, text);
                }
            } catch(e){
                console.log(e);
            }
        }

        return (

            <div id="modal" onClick={ e => props.show(false) }>
            <div id="modal-inner" style={{textAlign:`center`}} onClick={ e => {
         e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

            } }>
                    <span style={{float:`right`,cursor:`pointer`}} onClick={ e => props.show(false) }>&times;</span>
                    <img id="qr" style={{width:`15em`,margin:`auto`,display:`block`}} src="/Monero.png" />
                    <span id="public" style={{fontSize:`1.1vw`,cursor:`grab`}} onClick={ (e) => {
                        copyTextToClipboard( ADDRESS );
                        }}>{ADDRESS} &#128203;{ copied && ` COPIED` }</span>
                </div>
            </div>

        );

    };

export const XMRButton = ( props ) => <button type="button" onClick={ e => props.show(true) }><img src={XMR} alt="monero" style={{height:`1.4em`,verticalAlign:`middle`}}/> Buy me a tea </button>

export default XMRModal

